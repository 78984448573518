@use '../variables' as v

.backBar
    background: linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%)
    width: 7px
    width: 7px
    height: 130px
    border-radius: 100px
    background-size: 100% 200%
    animation: colorSlide 3s ease-out infinite, nudgeBar 3s ease-out infinite

@keyframes colorSlide
    0%
       background-position: 0% 100%
    50%
        background-color: v.$clr-main-dark
        background-position: 0% 0%
    100%
        background-position: 0% 0%

@keyframes nudgeBar
    0%
        transform: translateY(0)
    50%
        transform: translateY(8px)
    100%
        transform: translateY(0)
        