@use '../variables' as v

.navbar
    top: 0px
    position: sticky
    height: 3.5rem
    display: flex
    flex-direction: row
    padding: 1rem

    @media only screen and (min-width: v.$size-md)
        height: 4rem

    > *
        flex-basis: 100%
        display: flex
        flex-direction: row
        justify-content: center
        
    .icon-container
        gap: 0.75rem
    
    img
        height: 100%
    
    a
        height: 100%
