$clr-main-light:#F3FDFF
$clr-main-200:#B5CACE
$clr-main-400:#6D8D94
$clr-main-500:#51777E
$clr-main-dark:#043540
$clr-main-dark-transp:#043540AA


$size-sm:600px
$size-md:768px
$size-lg:992px
$size-xl:1200px
$size-2xl:1535px