@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Marcellus&display=swap");
html {
  font-size: 16px;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
}

h1 {
  font-size: 3.75rem;
  line-height: 1;
  font-family: Marcellus;
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 8rem;
  }
}

h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
  font-family: Marcellus;
}
@media only screen and (min-width: 768px) {
  h2 {
    font-size: 3.75rem;
    text-align: left;
    line-height: 1;
  }
}
@media only screen and (min-width: 1535px) {
  h2 {
    font-size: 6rem;
  }
}

p {
  font-size: 0.75rem;
  text-align: center;
  font-family: Lato;
  font-weight: 300;
}
@media only screen and (min-width: 600px) {
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 768px) {
  p {
    text-align: left;
  }
}
@media only screen and (min-width: 1535px) {
  p {
    font-size: 1.25rem;
    line-height: 1.75;
  }
}

.wrapper {
  height: 100vh;
  --tw-scroll-snap-strictness: mandatory;
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
  overflow-y: scroll;
  color: #F3FDFF;
  background-color: #043540;
}

.title-page {
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  color: #043540;
  background-color: #F3FDFF;
}
.title-page .container {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: center;
  place-content: center;
  gap: 2.75rem;
}
.title-page .container > * {
  display: flex;
  justify-content: center;
}
.title-page .icon-buttons {
  gap: 0.75rem;
}

.about-page {
  height: 100vh;
  scroll-snap-align: center;
  padding-top: 3.5rem;
}
@media only screen and (min-width: 768px) {
  .about-page {
    padding-top: 4rem;
  }
}
@media only screen and (min-width: 768px) {
  .about-page .container {
    display: flex;
    place-content: center;
  }
}
.about-page .img-col {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .about-page .img-col {
    order: 2;
    flex-basis: 100%;
  }
}
.about-page img {
  height: 40vh;
}
@media only screen and (min-width: 768px) {
  .about-page img {
    height: 80vh;
  }
}
@media only screen and (min-width: 768px) {
  .about-page .text-col {
    order: 1;
    flex-basis: 100%;
  }
}
.about-page .text-col .text-container {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 600px) {
  .about-page .text-col .text-container {
    padding: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .about-page .text-col .text-container {
    padding: 6rem;
    height: 100%;
  }
}
@media only screen and (min-width: 1535px) {
  .about-page .text-col .text-container {
    padding: 8rem;
  }
}
.about-page .text-col h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media only screen and (min-width: 600px) {
  .about-page .text-col h2 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.experience-page {
  height: 100vh;
  scroll-snap-align: center;
  padding-top: 3.5rem;
}
@media only screen and (min-width: 768px) {
  .experience-page {
    padding-top: 4rem;
  }
}
.experience-page .container {
  display: flex;
  flex-direction: column;
}
.experience-page h2 {
  padding: 1rem 0;
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .experience-page h2 {
    padding: 2.5rem 0;
  }
}
.experience-page .tile-container {
  display: flex;
  flex-direction: row;
  width: 70vw;
  height: 65vh;
  place-self: center;
}

.projects-page {
  height: 100vh;
  scroll-snap-align: center;
  padding-top: 3.5rem;
}
@media only screen and (min-width: 768px) {
  .projects-page {
    padding-top: 4rem;
  }
}

.exp-tile {
  flex: 1;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  transition: all 0.8s ease;
}
.exp-tile:hover {
  flex: 7;
}
.exp-tile:hover .bg {
  opacity: 80%;
}
.exp-tile:hover .body {
  width: 100%;
  height: auto;
  padding-left: 1em;
  opacity: 1;
  background-color: rgba(4, 53, 64, 0.6666666667);
  transition: all 0.5s ease, opacity 0.4s ease 0.8s;
}
.exp-tile:hover .title {
  min-width: 100%;
  width: 100%;
  transform: rotate(0deg);
  background-color: rgba(4, 53, 64, 0.6666666667);
  transition: all 0.5s ease, background-color 0.4s ease 0.8s;
}
.exp-tile .bg {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 30%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: all 0.8s ease;
}
.exp-tile .title {
  position: relative;
  padding-top: 1em;
  padding-left: 1em;
  min-width: 60vh;
  text-align: left;
  transform: rotate(-90deg) translatey(3em);
  transform-origin: 0 100%;
  transition: all 0.5s ease, background-color 0s linear;
  background-color: transparent;
}
.exp-tile .title span {
  font-weight: 700;
}
.exp-tile .body {
  position: relative;
  padding-top: 1em;
  width: 0%;
  height: 0%;
  opacity: 0;
  padding-left: 1em;
  transition: all 0.5s ease, opacity 0s linear;
  background-color: transparent;
}

.bg-ubc {
  background-image: url("../media/ubc.jpg");
}

.bg-seabc {
  background-image: url("../media/seabc.jpg");
}

.bg-cmolik {
  background-image: url("../media/cmolik.jpg");
}

.bg-eq {
  background-image: url("../media/eq.jpg");
}

.bg-ubc2 {
  background-image: url("../media/ubc2.jpg");
}

.navbar {
  top: 0px;
  position: sticky;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
@media only screen and (min-width: 768px) {
  .navbar {
    height: 4rem;
  }
}
.navbar > * {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.navbar .icon-container {
  gap: 0.75rem;
}
.navbar img {
  height: 100%;
}
.navbar a {
  height: 100%;
}

.backBar {
  background: linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
  width: 7px;
  width: 7px;
  height: 130px;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: colorSlide 3s ease-out infinite, nudgeBar 3s ease-out infinite;
}

@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-color: #043540;
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes nudgeBar {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}
.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

