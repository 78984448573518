@use '../variables' as v

.about-page
    height: 100vh
    scroll-snap-align: center
    padding-top: 3.5rem
    @media only screen and (min-width: v.$size-md)
        padding-top: 4rem

    .container
        @media only screen and (min-width: v.$size-md)
            display: flex
            place-content: center

    .img-col
        display: flex
        justify-content: center
        @media only screen and (min-width: v.$size-md)
            order: 2
            flex-basis: 100%
    
    img
        height: 40vh
        @media only screen and (min-width: v.$size-md)
            height: 80vh

    .text-col
        @media only screen and (min-width: v.$size-md)
            order: 1
            flex-basis: 100%
        
        .text-container
            padding: 0.75rem
            display: flex
            flex-direction: column
            justify-content: center
            @media only screen and (min-width: v.$size-sm)
                padding: 2rem
            @media only screen and (min-width: v.$size-md)
                padding: 6rem
                height: 100%
            @media only screen and (min-width: v.$size-2xl)
                padding: 8rem
        
        h2
            padding-top: 1rem
            padding-bottom: 1rem
            @media only screen and (min-width: v.$size-sm)
                padding-top: 2.5rem
                padding-bottom: 2.5rem
 